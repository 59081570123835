<template>
  <div class="mb-5">
    <Swiper :sliderImages="swiperData"/>
    <div id="about" class="pt-4 pb-2">
      <h1>{{$t('TITLE.ABOUT_US')}}</h1>
    </div>
    <div class="px-5 justify-content-between company-services pb-5">
        <div class="text-left">
          <h3>{{$t('COMPANY.ESTABLISHED_YEAR', {'year':2014})}}</h3>
          <p v-html="$t('COMPANY.INFO.HENG_HUAT')"></p>
          <ul class="history mt-5">
            <li
              v-for="(history,ind) of servicesData"
              :key="ind"
              class="d-flex align-items-center mb-4"              
              >
              <div class="arrow-icon">
                <img :src="rightArrowIcon" />
              </div>
              <div class="ml-4 description">
                <div class="title">
                  {{$t(history.title)}}
                </div>
                <div class="info">
                  {{$t(history.description)}}
                </div>
                
              </div>
            </li>
          </ul>
        </div>
    </div>
    <!-- Contact Us Section Starts here -->
    <div id="contact-us" class="mt-5 mb-5">
        <h1>{{$t('TITLE.CONTACT_US')}}</h1>
      </div>
      <div class="d-flex px-5 justify-content-between contact-us-info">
        <div class="google-map-container">
          <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Heng%20Huat%20Fruits%20Sdn%20Bhd+(Heng%20Huat%20Fruits%20Sdn%20Bhd)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
          <div class="company-description text-left">
            <div class="mt-5">
              <p>{{$t("ACTION.EMAIL")}} 📧  &nbsp&nbsp : <a href = "mailto: hhfsb8472@gmail.com">hhfsb8472@gmail.com</a></p>
              <div class="d-flex">
                <p>{{$t("ACTION.ADDRESS")}} &nbsp&nbsp : &nbsp</p>
                <p>No 7 Jalan Mawar 1, <br/>
                    Taman Mawar 85010, <br/>
                    Buloh Kasap Segamat, Johor</p>
              </div>
              <div class="d-flex"> 
                <p class="contact-numbers-label">{{$t('ACTION.PHONE_NO')}} : &nbsp</p>
                <p class="contact-numbers">
                  <span><a href="tel:+60197188887">019-718 8887</a></span>
                  <span><a href="tel:+60197751558">019-775 1558</a></span>
                  <span><a href="tel:+601155042606">011-5504 2606</a></span>
                  <span><a href="tel:+601110808893">011-1080 8893</a></span>
                  <span><a href="tel:+079444407">07-9444 407</a></span>
                </p>
              </div>
              <Icons
                :iconContainerSize="'50%'"
                :iconSize="'25%'"
                :hasFacebook="true"
                :hasWhatsapp="true"
                :facebookLink="'https://www.facebook.com/hhfsd8472/'"
                :whatsappLink="'https://api.whatsapp.com/send?phone=60197188887'"/>
            </div>
          </div>
      </div>
    <!-- Contact Us Section End here -->     
  </div>
</template>

<script>
import rightArrowIcon from '@/assets/icons/right_arrow.png'
import swiperImageData from '@/data/swiperImage'
import servicesData from '@/data/details'
import Icons from '@/components/Icons'
import Swiper from '@/components/Swiper'
export default {
  name:"Heng_Huat",
  components:{
    Swiper,
    Icons
  },
  data(){
    return{
      swiperData: {...swiperImageData.henghuat},
      servicesData:{...servicesData.hengHuat.history},
      rightArrowIcon: rightArrowIcon,
    }
  }
}
</script>

<style lang="scss" scoped>
.google-map-container{
  width: 35%;
  height:45vh;
  position: relative;

  iframe{
    position: absolute;
    left:0;
  }
}

.history{
  padding: 0;
    li{
      margin-bottom: 5px;
      cursor: pointer;
      transition: all .2s;

      .arrow-icon{
          width: 5%;
        img{
          width: 100%;
        }
      }

      &.nested{
        list-style: none;
        .arrow-icon{
          opacity:  0;
        }
      }

      &:hover{
        transform: translateX(25px);
      }

      .description{
        width: 85%;
      }
    }


  }

.company-description{
  width:60%;
}

.contact-numbers{
  span{
    margin-right: 10px;
  }
}

.company-services, #about{
  background:#f7f7f7
}


@media screen and (max-width:600px){
    .company-services{
        flex-direction: column-reverse;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .company-description, .google-map-container{
      width: 100%;
    }

    .contact-numbers-label{
      width: 25%;
    }

    .contact-numbers{
      width: 75%;
      display: flex;
      flex-wrap: wrap;

    }

    .history{
      .arrow-icon{
        width: 10% !important;
      }
      .description{
        width: 85%;

        .title{
          font-size: 1.1rem;
        }
        .info{
          font-size: 0.9rem;
        }
      }
    }

  #contact-us{
    margin-bottom: 15px !important;
  }
  .contact-us-info{
    flex-direction: column-reverse;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

</style>